import { render, staticRenderFns } from "./WalletPicker.vue?vue&type=template&id=34a2b87b&scoped=true&"
import script from "./WalletPicker.vue?vue&type=script&lang=js&"
export * from "./WalletPicker.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "34a2b87b",
  null
  
)

export default component.exports